<template>
  <div>
    <NavTitle class="mb16" :title="showBack ? '返回' : '用户列表'" :showBack="showBack" @back="handleBack">
      <template #right>
        <div class="input-row">
          <el-input class="input" placeholder="请输入手机号/公司/姓名/ID, 批量搜索关键词使用英文逗号分隔" clearable v-model="searchWord" @clear="handleClear" @change="getUserList"></el-input>
          <el-button slot="append" type="primary" icon="el-icon-search"  @click="getUserList"></el-button>
        </div>
      </template>
    </NavTitle>
    <div class="pl20 pr20 mt16">
      <el-table
        :data="userList"
        style="width: 100%"
        v-loading="tableLoading">
        <el-table-column
          prop="uid"
          label="ID">
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="手机号">
        </el-table-column>
        <el-table-column
          prop="name"
          label="用户类型">
          <template slot-scope="scope">
            <span>{{scope.row.userType == 1 ? '用户':'商户'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="appName"
          label="APP名称">
        </el-table-column>
        <el-table-column
          prop="ch"
          label="渠道">
        </el-table-column>
        <el-table-column
          width="180"
          prop="registerTime"
          label="注册时间">
        </el-table-column>
        <el-table-column
          label="状态">
          <template slot-scope="scope">
            <span>{{statusTypes[scope.row.status] || ''}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="冻结/注销原因">
          <template slot-scope="scope">
            <span>{{scope.row.reason?.c || ''}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="冻结/注销时间">
          <template slot-scope="scope">
            <span>{{formatTime(scope.row.reason?.dt) || ''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="230">
          <template slot-scope="scope">
            <template v-if="scope.row.userType == 1 && scope.row.status != 3">
              <span class="pointer theme-text" @click="toDetail(scope.row)">详情</span>
              <span>
                <span class="gap-line">|</span>
                <el-dropdown @command="handleCommand($event, scope.row)">
                  <span class="el-dropdown-link">
                    <span class="pointer theme-text">更多<i class="el-icon-arrow-down el-icon--right"></i></span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <div v-auth="'MODIFY_ENROLL_RISK_ALLOW_LIST'">
                      <el-dropdown-item :command="1" v-if="!scope.row.allowRiskEnroll">加入风险报名白名单</el-dropdown-item>
                      <el-dropdown-item :command="2" v-if="scope.row.allowRiskEnroll">移出风险报名白名单</el-dropdown-item>
                    </div>
                    <div v-auth="'USER_STATUS_MANAGE'">
                      <el-dropdown-item :command="3" v-if="scope.row.status == 1">冻结</el-dropdown-item>
                      <el-dropdown-item :command="4" v-if="scope.row.status == 2">解冻</el-dropdown-item>
                      <el-dropdown-item :command="5" v-if="scope.row.status != 3">注销</el-dropdown-item>
                    </div>
                    <el-dropdown-item :command="6">修正信息</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex justify-center mt12">
        <el-pagination
          layout="prev, pager, next"
          :total="userList.length">
        </el-pagination>
      </div>
    </div>
    <!-- 冻结/注销弹窗 -->
    <FreezePopup ref="frezzeRef" @update="getUserList"></FreezePopup>
    <!-- 修正信息 -->
    <CorrectInfoPopup ref="correctRef" @update="getUserList"></CorrectInfoPopup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import Pagination from '@/components/Pagination'
import FreezePopup from './components/FreezePopup.vue'
import CorrectInfoPopup from './components/CorrectInfoPopup.vue'
import { searchUserApi, userStatusOperateApi } from '@/api/admin.js'
import { appCtrConfigApi } from '@/api/resConf.js'
import { dateFormatUtil } from '@/utils/util.js'
export default {
  name: 'UserList',
  components: {
    NavTitle,
    Pagination,
    FreezePopup,
    CorrectInfoPopup
  },
  data() {
    return {
      userList: [],
      searchWord: '',
      statusTypes: {
        1: '正常',
        2: '冻结',
        3: '销户',
      },
      tableLoading: false
    }
  },
  computed: {
    showBack() {
      return this.$route.query.uid ? true : false
    }
  },
  activated() {
    if(this.$route.query.uid) this.searchWord = this.$route.query.uid
    this.getUserList()
  },
  beforeRouteUpdate(to, from, next) {
    this.handleBack()
    next()
  },
  methods: {
    getUserList() {
      if(!this.searchWord.trim()) return
      const formData = {
        searchWord: this.searchWord.trim()
      }
      if(this.searchWord.includes(',')) {
        Object.assign(formData, {
          mobiles: this.searchWord.split(',')
        })
      }
      console.log('formData', formData)
      this.tableLoading = true
      searchUserApi(formData).then(res => {
        console.log('用户列表', res)
        this.tableLoading = false
        const list = res.data.searchUsers || []
        this.userList = list.filter(item => {
          if(item.reason) {
            try {
              item.reason = JSON.parse(item.reason) || ''
            } catch (error) {}
          }
          return item.userType == 1
        })
      }).catch(err => {
        this.tableLoading = false
        this.$tips({message: err, type:'error'})
      })
    },
    handleClear() {
      this.userList = []
    },
    userWhiteManage(act, id) {
      const formData = {
        act,
        uids:[id]
      }
      console.log('formData', formData)
      appCtrConfigApi(formData).then(res => {
        console.log('用户加白结果', res)
        this.$tips({message: res.msg, type:'success'})
        this.getUserList()
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    userStatusManage(act, uid) {
      userStatusOperateApi({
        act,
        type: 1,
        uid
      }).then(res => {
        this.$tips({message: res.msg, type:'success'})
        this.getUserList()
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    formatTime(time) {
      return time && dateFormatUtil(time, '-', true)
    },
    handleCommand(e, info) {
      if(e == 1) {
        // 加入风险报名白名单
        this.userWhiteManage(1, info.uid)
      }
      if(e == 2) {
        // 移出风险报名白名单
        this.userWhiteManage(2, info.uid)
      }
      if(e == 3) {
        // 冻结
        this.$refs.frezzeRef.open({act: 1, uid: info.uid})
      }
      if(e == 4) {
        // 解冻
        this.userStatusManage(2, info.uid)
      }
      if(e == 5) {
        // 注销
        this.$refs.frezzeRef.open({act: 3, uid: info.uid})
      }
      if(e == 6) {
        // 修正信息
        this.$refs.correctRef.open({uid: info.uid, resumeDetail: info.resumeDetail})
      }
    },
    toDetail(info) {
      this.$router.push({
        path: '/detail/user',
        query: {
          uid: info.uid
        }
      })
    },
    handleBack() {
      this.searchWord = ''
      this.userList = []
    },
  }
}
</script>

<style lang="scss" scoped>
.input-row {
  width: 600px;
  display: flex;
  align-items: center;
}
</style>